import React from "react";

import {
  BlogPostStyled,
  BlogPostHeroStyled,
  BlogPostTitleStyled,
  BlogPostContentStyled,
} from "./styles";
import Wysiwyg from "./../Wysiwyg";
import Img from "./../Image";

interface IProps {
  data: [];
}

const BlogPost: React.FC<IProps> = ({ data }) => {
  return (
    <BlogPostStyled narrow>
      {data.featuredImage.node.imageFile && (
        <BlogPostHeroStyled>
          <Img
            fluid={["", data.featuredImage.node.imageFile]}
            alt={`${data.title} image`}
          />
        </BlogPostHeroStyled>
      )}
      <BlogPostTitleStyled dangerouslySetInnerHTML={{ __html: data.title }} />

      <BlogPostContentStyled>
        <Wysiwyg wide mediumType>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </Wysiwyg>
      </BlogPostContentStyled>
    </BlogPostStyled>
  );
};

export default BlogPost;
